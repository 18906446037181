import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import HeaderItem from "../../data/header.json";
import { useAppContext } from "@/context/Context";

const Nav = () => {
  const router = useRouter();
  const { showItem, setShowItem } = useAppContext();

  const isActive = (href) => router.pathname === href;

  return (
    <>
      <ul className="mainmenu">
        <li>
          <Link href="https://roninservers.com.au/">Home</Link>
        </li>
        <li className="with-megamenu has-menu-child-item position-relative">
          <Link href="https://payments.roninservers.com.au/store/game-hosting">Game Hosting</Link>
          <div
            className={`rainbow-megamenu right-align with-mega-item-2 ${
              showItem ? "" : "d-block"
            }`}
          >
            <div className="wrapper p-0">
              <div className="row row--0">
                <div className="col-lg-12 single-mega-item">
                  <h3 className="rbt-short-title">Game Hosting</h3>
                  <ul className="mega-menu-item">
                    {HeaderItem &&
                      HeaderItem.navGameItems.map((data, index) => (
                        <li key={index}>
                          <Link
                            href={data.link}
                            className={isActive(data.link) ? "active" : ""}
                          >
                            {data.text}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="with-megamenu has-menu-child-item position-relative">
          <a
            href="#"
            onClick={() => setShowItem(!showItem)}
            className={`${!showItem ? "open" : ""}`}
          >
            Company
          </a>
          <div
            className={`rainbow-megamenu right-align with-mega-item-2 ${
              showItem ? "" : "d-block"
            }`}
          >
            <div className="wrapper p-0">
              <div className="row row--0">
                <div className="col-lg-12 single-mega-item">
                  <h3 className="rbt-short-title">Company</h3>
                  <ul className="mega-menu-item">
                    {HeaderItem &&
                      HeaderItem.navCompanyItem.map((data, index) => (
                        <li key={index}>
                          <Link
                            href={data.link}
                            className={isActive(data.link) ? "active" : ""}
                          >
                            {data.text}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};

export default Nav;
